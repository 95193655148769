
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Form as IForm, FormSchema, Field } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Form from '@/components/form/Form.vue'
import _snakeCase from 'lodash/snakeCase'
import _mapKeys from 'lodash/mapKeys'
import _fromPairs from 'lodash/fromPairs'
import Loading from '@/components/Loading.vue'
import _isEqual from 'lodash/isEqual'
import { ValueType } from '@/models/Value'

@Component({
  components: {
    Loading,
    ComponentHeader,
    Form
  },
  apollo: {
    itemData: {
      query: gql`query getFormItem ($formId : ID, $itemId : ID) {
        itemData: form (formId: $formId) {
          _id
          item(itemId: $itemId) {
            _id
            data
          }
        }
      }`,
      variables () {
        return {
          formId: this.formConfig._id,
          itemId: this.formConfig.updateVariableName ? this.viewParams[this.formConfig.updateVariableName] : null
        }
      },
      skip () {
        return !this.formConfig.updateVariableName || this.preview
      }
    }
  }
})
export default class ViewFormView extends Vue {
  @Prop({ type: String }) environmentId !: string
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Object, required: true }) formConfig !: IForm
  @Prop({ type: Boolean }) disabled ?: boolean
  @Prop({ type: Boolean, default: false }) forceMobile !: boolean

  /** Server validation errors */
  validationErrors : Record<string, string[]> = {}
  /** Busy State */
  busy = false
  /** Saved Data */
  itemData : any = {}



  get schema () {
    return {
      ..._fromPairs((this.formConfig.fieldsList || []).map(f => [f.fieldName, f])),
      ...this.formConfig.serializedParams
    } as FormSchema
  }

  get savedValue () {
    const saved = this.itemData && this.itemData.item && this.itemData.item.data
    const def : Record<string, any> = {}
    Object.keys(this.schema).forEach(k => {
      const field = this.schema[k]
      if (field.defaultValue) {
        def[k] = field.defaultValue
      }
    })
    return { ...saved, ...def }
  }

  get normalizedIcon () {
    let icon = this.formConfig.submitButtonIcon
    if (!icon) {
      icon = this.formConfig.type === 'create' ? 'add' : 'save'
    }
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  get normalizedButtonText () {
    if (this.formConfig.submitButtonText) return this.formConfig.submitButtonText
    return this.formConfig.type === 'create' ? 'Crear' : 'Guardar'
  }

  async submit () {
    if (this.preview) return

    const form = this.$refs.form as any
    if (form.busy || !form.valid) return

    this.$emit('submit', form.value)
    this.busy = true

    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation submitForm($formId : ID, $itemId : ID, $data : JSON) {
          result: submitForm(formId: $formId, itemId: $itemId, data: $data) {
            _id
            data
            createdAt
          }
        }`,
        variables: {
          formId: this.formConfig._id,
          itemId: this.formConfig.updateVariableName ? this.viewParams[this.formConfig.updateVariableName] : null,
          data: form.value
        }
      })
      if (!this.formConfig.updateVariableName) form.undo()
      this.$emit('success', data.result)
    } catch (e) {
      console.error(e)
      // TODO: Handle other kinds of errors
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err : any) => {
          if (err.error === 'validationError') {
            this.$emit('validationErrors', err.validationErrors)
            this.$set(this, 'validationErrors', _mapKeys(err.validationErrors, (v, k) => k.replace(/^data\./, '')))
            return
          }
        })
      }
      this.$emit('error', e)
    } finally {
      this.busy = false
    }
  }
}
