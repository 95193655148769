























import { Component, Vue, Prop } from 'vue-property-decorator'
import { CollectionData, Form as IForm, ViewItem } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import ViewForm from './ViewForm.vue'
import openLink from '@/utils/route/openLink'
import _pick from 'lodash/pick'
import { ApolloError } from 'apollo-client'
import { FormFragment } from './fragments'
import resolveLink from '@/utils/route/resolveLink'

@Component({
  components: {
    Loading,
    ComponentHeader,
    ViewForm
  },
  apollo: {
    form: {
      query: gql`query getForm ($formId : ID) {
        form: form (formId: $formId) {
          ...Form
        }
      }
      ${FormFragment}`,
      variables () {
        return {
          formId: this.componentId
        }
      }
    }
  }
})
export default class FormView extends Vue {
  @Prop({ type: String }) environmentId !: string
  @Prop({ type: String }) componentId !: string
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) editing !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition !: ViewItem
  @Prop({ type: Boolean, default: false }) forceMobile !: boolean
  @Prop({ type: Boolean, default: false }) showCloseButton !: boolean

  form : IForm | null = null

  async handleSuccess (result : CollectionData | null) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Se completó con éxito!',
      timeout: 3000
    })
    // Extract result parameters
    const resultParams = { ...this.viewParams }
    if (result) {
      Object.assign(resultParams, {
        _id: result._id,
        createdAt: result.createdAt,
        ...result.data
      })
    }
    // Redirect to target view
    if (this.form!.onSuccessViewPath) openLink(this.form!.onSuccessViewPath, resultParams, this.form!.onSuccessExternalTab)
    // Assign current view parameters
    if (this.form!.onSuccessEnvironmentVariables) {
      this.$emit('setParams', _pick(resultParams, this.form!.onSuccessEnvironmentVariables))
    }
    if (this.form!.postItemToUrl) {
      try {
        const targetUrl = resolveLink(this.form!.postItemToUrl, resultParams)
        await fetch(targetUrl.href, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(resultParams)
        })
      } catch (e) {
        console.error(`Error al enviar datos del formulario ${this.form!._id}:`, e)
      }
    }
    this.$emit('result', resultParams)
  }

  async handleError (e : ApolloError) {
    let message = e.message
    if (e.graphQLErrors) message = e.graphQLErrors.map(e => e.message).join(', ')
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + message,
      color: 'error',
      timeout: 10000
    })
    this.$emit('close')
  }

  get formVisible () {
    if (!this.form) return false
    if (this.form.type !== 'update' || this.preview) return true
    return !this.form.hideEmpty || this.viewParams[this.form.updateVariableName] != null
  }

  get formActive () {
    if (this.preview) return false
    if (!this.form) return false
    if (this.form.type !== 'update') return true
    if (this.form.updateVariableName) {
      return this.viewParams[this.form.updateVariableName] != null
    }
    return true
  }
}
